import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { createStyles, makeStyles } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Paragraph2Regular } from 'front-components/src/themes';

const languageMap = {
  en: { label: 'English', dir: 'ltr', countryCode: 'gb', active: true },
  de: { label: 'German', dir: 'ltr', countryCode: 'de', active: false },
  nl: { label: 'Dutch', dir: 'ltr', countryCode: 'nl', active: false },
};

const LanguageSelect = () => {
  const useStyles = makeStyles(() => {
    return createStyles({
      flagIcon: {
        height: '15px',
        paddingRight: '5px',
      },
      button: {
        marginLeft: '-5px',
        marginTop: '-5px',
      },
      Paragraph2Regular: {
        ...Paragraph2Regular,
      },
    });
  });

  const selected = localStorage.getItem('i18nextLng') || 'en';
  const { t } = useTranslation();
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <>
      <Button
        className={classes.button}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
      >
        <CircleFlag
          countryCode={languageMap[selected].countryCode}
          className={classes.flagIcon}
        />
        <span className={classes.Paragraph2Regular}>
          {languageMap[selected].label}
        </span>
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <List>
            <ListSubheader>{t('Select language')}</ListSubheader>
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                <CircleFlag
                  countryCode={languageMap[item].countryCode}
                  className={classes.flagIcon}
                />
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default LanguageSelect;
