import * as React from 'react';
import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';
import LanguageSelect from './../languages/language-selector';
import { PortalLayout, SmallCard } from 'front-components/src/components';
import Grid from '@material-ui/core/Grid';
import { AdminMenu } from '../components/asside-menu/asside-menu';
import { PortalHeader } from '../components/portal-header/portal-header';

const Language = () => {
  const asside = <AdminMenu />;

  return (
    <main>
      <title>Language</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SmallCard title="Language & Region">
              <p></p>
              <LanguageSelect />
              <p></p>
            </SmallCard>
          </Grid>
        </Grid>
      </PortalLayout>
    </main>
  );
};

export default Language;
